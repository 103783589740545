@mixin css3-prefix($property, $value) {
	-webkit-#{$property}: #{$value};
	-khtml-#{$property}: #{$value};
	-moz-#{$property}: #{$value};
	-ms-#{$property}: #{$value};
	-o-#{$property}: #{$value};
	#{$property}: #{$value};
}

@mixin border-radius($radius...) {
	@include css3-prefix('border-radius', $radius);
}

@mixin user-select($val: none) {
	@include css3-prefix('user-select', $val);
}

@mixin background-size($width: 100%, $height: $width) {
	@if type-of($width) == 'number' and $height != null {
		@include css3-prefix('background-size', $width $height);
	} @else {
		@include css3-prefix('background-size', $width);
	}
}

@mixin box-sizing($type: border-box) {
	@include css3-prefix('box-sizing', $type);
}

@mixin box-shadow( $string ) {
	@include css3-prefix('box-shadow', $string);
}

@mixin transition($properties...) {

	@if length($properties) >= 1 {
		@include css3-prefix('transition', $properties);
	}

	@else {
		@include css3-prefix('transition',  'all 0.15s ease 0s');
	}
}

@mixin opacity($opacity: 0.5) {
	$opacityMultiplied: ($opacity * 100);
	filter:         alpha(opacity=$opacityMultiplied);
	-ms-filter:     "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacityMultiplied + ")";
	@include css3-prefix('opacity', $opacity);
}

@mixin backgroundImg($img: ''){
	@if ($img != "" ){
		background: url($img) no-repeat 50% 50%;
	} @else {
		background: no-repeat 50% 50%;
	}
}







/// Background Gradient
/// @param {Color} $startColor [#3C3C3C] - Start Color
/// @param {Color} $endColor [#999999] - End Color

@mixin background-gradient($startColor: #3C3C3C, $endColor: #999999) {
	background-color: $startColor;
	background-image: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
	background-image: -webkit-linear-gradient(top, $startColor, $endColor);
	background-image:    -moz-linear-gradient(top, $startColor, $endColor);
	background-image:     -ms-linear-gradient(top, $startColor, $endColor);
	background-image:      -o-linear-gradient(top, $startColor, $endColor);
	background-image:         linear-gradient(top, $startColor, $endColor);
//	filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
}

@mixin background-horizontal($startColor: #3C3C3C, $endColor: #999999) {
	background-color: $startColor;
	background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
	background-image: -webkit-linear-gradient(left, $startColor, $endColor);
	background-image:    -moz-linear-gradient(left, $startColor, $endColor);
	background-image:     -ms-linear-gradient(left, $startColor, $endColor);
	background-image:      -o-linear-gradient(left, $startColor, $endColor);
	background-image:         linear-gradient(left, $startColor, $endColor);
//	filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}', gradientType='1');
}

@mixin background-radial($startColor: #FFFFFF, $startPos: 0%, $endColor: #000000, $endPos:100%) {
	background: -moz-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop($startPos,$startColor), color-stop($endPos,$endColor));
	background: -webkit-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
	background: -o-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
	background: -ms-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
	background: radial-gradient(ellipse at center, $startColor $startPos,$endColor $endPos);
}



/// Columns
/// @param {Integer} $count [3] - Count
/// @param {Integer} $gap [10] - Gap
/// @require {mixin} css3-prefix

@mixin columns($count: 3, $gap: 10) {
	@include css3-prefix('column-count', $count);
	@include css3-prefix('column-gap', $gap);
}


/// Flex
/// @param {Integer} $value [1] - Value
/// @require {mixin} css3-prefix

@mixin flex($value: 1) {
	@include css3-prefix('box-flex', $value);
	flex: $value;
}



/// Font Face
/// @param {Font} $fontFamily [myFont] - Font Family
/// @param {String} $eotFileSrc ['myFont.eot'] - Eot File Source
/// @param {String} $woffFileSrc ['myFont.woff'] - Woff File Source
/// @param {String} $ttfFileSrc ['myFont.ttf'] - Ttf File Source
/// @param {String} $svgFileSrc ['myFont.svg'] - Svg File Source

@mixin font-face($fontFamily: myFont, $eotFileSrc: 'myFont.eot', $woffFileSrc: 'myFont.woff', $ttfFileSrc: 'myFont.ttf', $svgFileSrc: 'myFont.svg', $svgFontID: '#myFont') {
	font-family: $fontFamily;
	src: url($eotFileSrc)  format('eot'),
	url($woffFileSrc) format('woff'),
	url($ttfFileSrc)  format('truetype'),
	url($svgFileSrc + $svgFontID) format('svg');
}



/// Outline Radius
/// @param {Size} $radius [5px] - Radius
/// @require {mixin} css3-prefix

@mixin outline-radius($radius: 5px) {
	@include css3-prefix('outline-radius', $radius);
}


/// Resize
/// @param {*} $directoin [both] - Direction
/// @require {mixin} css3-prefix

@mixin resize($direction: both) {
	@include css3-prefix('resize', $direction);
}


@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
	@include css3-prefix('transform', rotate($deg + deg));
}

@mixin transform($params) {
	@include css3-prefix('transform', $params);
}

@mixin transform-origin($params) {
	@include css3-prefix('transform-origin', $params);
}

@mixin transform-style($style: preserve-3d) {
	@include css3-prefix('transform-style', $style);
}



/// Keyframes
/// @param {*} $animation-name - Animation name
/// @content [Animation css]

@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($str) {
	@include css3-prefix('animation', $str);
}






@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

@mixin pxToRem($property, $values...) {
	$max: length($values);
	$pxValues: '';
	$remValues: '';

	@for $i from 1 through $max {
		$value: strip-unit(nth($values, $i));
		$pxValues: #{$pxValues + $value/16}rem;

		@if $i < $max {
			$pxValues: #{$pxValues + ' '};
		}
	}

	@for $i from 1 through $max {
		$value: strip-unit(nth($values, $i));
		$remValues: #{$remValues + $value}px;

		@if $i < $max {
			$remValues: #{$remValues + ' '};
		}
	}

	#{$property}: $remValues;
	#{$property}: $pxValues;
}