.aside {
	width: $asideWidth;
	background-color: #fff;
	position: fixed;
	min-width: 167px;

	top: 0;
	left: 0;
	bottom: 0;
	height: 100%;
	@include box-shadow(2px 0 3px rgba(38, 38, 38, 0.2));
	z-index: $zAside;

	@include box-sizing();
	overflow: auto;

	&__inner {
		padding: 20px 10.5%;
	}

	&__logo {
		color: #000;
		font-size: 26px;
		margin-bottom: 30px;
		display: block;
	}

	&__menu {

		&-item {
			padding: 5px 0;

			&_sub {
				font-size: 16px;
				color: #000;
				font-family: helveticaneuecyr, sans-serif;
				font-weight: 500;
				cursor: pointer;

				@include breakpoint($bp) {
					font-size: 21px;
				}

				span {
					font-size: 14px;
					font-family: 'Open Sans', sans-serif;
					color: #7d7d7d;
					font-weight: 400;
					padding-left: 10px;
				}
			}
		}

		&-link {

			&.active {
				color: $colorYellow;
				text-decoration: underline;
			}

			&:hover {
				color: $colorYellow;
				text-decoration: underline;
			}
		}
	}

	&__submenu {
		display: none;

		&.is-open {
			display: block;
		}

		& + * {
			padding-top: 15px;
		}
	}

	&__label {
		width: 82px;
		margin-top: 20px;
		height: 115px;
		background: url("/img/aside-label.svg") no-repeat 0 0;
		margin-left: -13.5%;
	}
}