.header{
	height: $headerHeight;
	background-color: #fff;

	.inner {
		margin: 0 1.3%;
		text-align: right;
	}

	&__note {
		float: left;
		padding: 30px 0 0;
	}

	&__contact {
		display: inline-block;
		vertical-align: middle;
		margin-right: 15px;

		.phone {
			display: inline-block;
			font-size: 21px;
			color: #000;
			font-family: 'helveticaneuecyr', sans-serif;
			margin-right: 10px;
		}
	}

	&__order {
		display: inline-block;
		vertical-align: middle;
		padding: 13px 0;
		margin-right: 20px;

		.btn {
			padding-right: 35px;
			padding-left: 35px;
		}
	}

	&__menu {
		display: inline-block;
		vertical-align: middle;
		padding: 13px 0;
		font-size: 0;

		&-item {
			background: #ededed no-repeat 50% 50%;
			@include border-radius(100px);
			height: 44px;
			width: 44px;
			display: inline-block;
			position: relative;

			&:hover {
				background-color: $colorYellow;
			}

			&_search {
				background-image: url("/img/ico-header-search.svg");
				margin-right: 10px;
			}
			&_calc {
				background-image: url("/img/ico-header-calc.svg");
				margin-right: 10px;
			}
			&_cart {
				background-image: url("/img/ico-header-cart.svg");
			}

			span {
				height: 22px;
				min-width: 22px;
				display: inline-block;
				top: -3px;
				right: -8px;
				position: absolute;
				@include border-radius(100px);
				background: $colorYellow;
				font-weight: 600;
				color: #000;
				text-align: center;
				line-height: 22px;
				padding: 0 5px;
				font-size: 10px;
				@include box-sizing();
			}
		}
	}


	&__search{
		display: none;

		position: absolute;
		top:13px;
		right: calc(1.3% + 50px);



		&.f-active{
			display: block;
		}



		.search__input{
			width: 720px;
		}
		.search__submit{
			@include breakpoint($bp){
				padding: 0 5px;
			}
		}
		.search__close{
			@include breakpoint($bp){
				margin: 0 5px;
			}
		}

		&-exemple{

			width: 600px;
			text-align: left;
			padding: 0 10px;
			margin-top: 20px;


			span{

				display: inline-block;
				vertical-align: top;
				white-space: nowrap;
				border: 1px solid #d3d3d3;
				color: #d3d3d3;
				@include border-radius(100px);
				height: 44px;
				line-height: 44px;
				padding: 0 25px;
				cursor: pointer;
				margin: 0 10px 20px 0;

				&:hover{
					color: $colorYellow;
					border-color: $colorYellow;
				}
			}
		}
	}
}
