$minWidth: 1320px;
$asideWidth: calc((100% * 0.345) - 288.12px);
$headerHeight: 70px;
$footerHeight: 215px;


$colorYellow: #fbda52;
$colorYellowHover: #fde47d;
$colorBlue: #1a6f9d;
$colorRed: #de6c40;
$colorLight: #c0bfbe;
$colorGreen: #54943f;
$colorGrey: #7d7d7d;

$zAside: 10;

$bp: 1600px;

@import '../../bower_components/breakpoint-sass/stylesheets/_breakpoint';
@import 'sass/mixins';
@import 'sass/normalize';

@import 'sass/slick';

@import 'sass/main';
@import 'sass/aside';
@import 'sass/header';
@import 'sass/footer';
@import 'sass/popup';
@import 'sass/autocomplete';

html {
	min-width: $minWidth;
	font-family: 'Open Sans', sans-serif;
	color: #7d7d7d;
	font-size: 13px;
	height: 100%;

	@include breakpoint($bp) {
		font-size: 14px;
	}
}

body {
	min-width: $minWidth;
	opacity: 1 !important;
	line-height: 1.2;
	height: 100%;
}

@import 'sass/fonts';