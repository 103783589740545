

.tooltip{
	position: absolute;
	display: none;
	text-align: center;
	margin-top: 12px;
	z-index: 120;

	&:before,
	&:after {
		content: '';
		display: block;
		border: 6px solid transparent;
		border-bottom-color: #fff;
		position: absolute;
		left: 0;
		margin-left: -6px;
		top:-11px;
	}
	&:before {
		top:-12px;
		border-bottom-color: #e8e8e8;
	}

	span{
		display: inline-block;
		padding: 7px 15px;
		background: #fff;
		position: relative;
		left: -50%;
		@include box-shadow(0 1px 3px rgba(0, 0, 0, .3));
		@include border-radius(15px);

		font-size: 13px;
	}

	&.show{
		display: block;
	}

}





.overflow_hidden {
	overflow: hidden;
}

.popup{
	display: none;
	color: #343434;

	&__inner{
		height: 100%;
		left: 0;
		overflow: auto;
		position: fixed;
		text-align: center;
		top: 0;
		white-space: nowrap;
		width: 100%;
		z-index: 102;

		&:before {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}

	}

	&__layout{
		@include border-radius(5px);
		@include box-sizing();
		background-color: #fff;
		box-sizing: border-box;
		display: inline-block;
		margin: 60px auto;
		padding: 30px 40px;
		text-align: left;
		position: relative;
		vertical-align: middle;
		white-space: normal;
		width: 430px;
		z-index: 102;
	}


	&__content{

	}

	&__title{
		@extend %title;
		margin-bottom: 25px;
	}

	&__text{
		margin-bottom: 25px;
	}

	&__close{
		@extend %roundBtn;

		position: absolute;
		top: 0px;
		right: -55px;

	}

	&__overlay{
		background: rgba(#1f4c7e, 0.9);
		display: block;
		height: 100%;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 101;

	}

	&__form{

		.form__field {
			padding: 10px 0;
		}
	}

}

