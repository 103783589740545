body{
	line-height: 1;
}

*{
	margin: 0;
	padding: 0;
	//vertical-align: baseline;
	border: 0;
	outline: 0;

	-moz-text-size-adjust: none;
	-ms-text-size-adjust: none;
	text-size-adjust: none;

	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;

	&:active,
	&:focus{
		outline: none;
	}
}



ol, ul {
	list-style: none;
}

a{
	color: inherit;
	text-decoration: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

img {
	border: 0;
	-ms-interpolation-mode: bicubic;
}

h1, h2, h3, h4, h5, h6 {
	font: inherit;
	margin: 0;
}


blockquote, q {
	quotes: none;

	&:before,
	&:after {
		content: '';
		content: none;
	}
}

.hidden{
	display: none;
}

select,
textarea,
button,
input[type=password],
input[type=text] {
	font: inherit;
	color: inherit;
	overflow: visible;
	background-color: transparent;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	&::-webkit-input-placeholder{
		color:currentColor;
		text-overflow:ellipsis;
		@include transition(opacity, 0.25s ease);
	}
	&::-moz-placeholder{
		color:currentColor;
		text-overflow:ellipsis;
		@include transition(opacity, 0.25s ease);
	}
	&:-moz-placeholder{
		color:currentColor;
		text-overflow:ellipsis;
		@include transition(opacity, 0.25s ease);
	}
	&:-ms-input-placeholder{
		color:currentColor;
		text-overflow:ellipsis;
		@include transition(opacity, 0.25s ease);
	}

	&:focus::-webkit-input-placeholder,
	&:focus::-moz-placeholder,
	&:focus:-moz-placeholder,
	&:focus:-ms-input-placeholder{
		opacity: 0;
	}

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}

textarea{
	resize: none;
	overflow: auto;
}

input{
	&[type="search"]{
		&::-webkit-search-cancel-button,
		&::-webkit-search-decoration {
			-webkit-appearance: none;
		}

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			height: auto;
		}

		&::-ms-clear {
			display: none;
		}
	}
}

label{
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

[hidden] {
	display: none;
}






/* mixins */

@mixin clearfixTop(){
	&:before {
		content: "";
		display: table;
	}
}
@mixin clearfixBottom(){
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.clearfix {
	@include clearfixTop();
	@include clearfixBottom();
}
