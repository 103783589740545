.autocomplete-suggestions {
	text-align: left;
	cursor: default;
	border: 1px solid #ccc;
	border-top: 0;
	background: #fff;
	box-shadow: -1px 1px 3px rgba(0, 0, 0, .1);

	/* core styles should not be changed */
	position: absolute;
	display: none;
	z-index: 9999;
	max-height: 254px;
	overflow: hidden;
	overflow-y: auto;
	box-sizing: border-box;
	margin-top: 10px;

	padding: 15px 0;

}

.autocomplete-suggestion {
	position: relative;
	padding: 0 30px;
	line-height: 23px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 1.02em;
	color: #000;

	b {
		font-weight: bold;
	}

	&:hover{
		background-color: $colorYellow;
	}

	&.selected {
		//background-color: $colorYellow;
	}
}

