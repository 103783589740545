.footer{
	margin-left: $asideWidth;
	height: $footerHeight;
	background-color: #fff;
	margin-top: -$footerHeight;
	z-index: 1;
	position: relative;

	&__firms {
		height: 75px;
		border-bottom: 1px solid #f5f4f2;
		font-family: 'helveticaneuecyr', sans-serif;
		font-size: 0;
		color: #000;

		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		align-items: stretch;
		justify-content: space-between;

		&-item {
			@include flex();
			@include box-sizing();
			padding: 0 15px;
			text-align: center;
			border-left: 1px solid #f5f4f2;

			&:hover {
				background-color: $colorYellowHover;
			}

			&:before {
				content: '';
				display: inline-block;
				height: 100%;
				vertical-align: middle;
			}

			span {
				font-size: 21px;
				vertical-align: middle;
				display: inline-block;
				max-width: 100%;
			}
		}
	}

	&__menu {
		height: 75px;
		border-bottom: 1px solid #f5f4f2;

		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		align-items: stretch;
		justify-content: space-between;

		&-item {
			@include flex();
			@include box-sizing();
			padding: 0 15px;
			text-align: center;

			&:hover {
				background-color: $colorYellowHover;
				color: #000;
			}

			&:before {
				content: '';
				display: inline-block;
				height: 100%;
				vertical-align: middle;
			}

			span {
				font-size: 14px;
				vertical-align: middle;
				display: inline-block;
				max-width: 100%;
			}
		}
	}

	&__info {

		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		justify-content: space-between;

		height: 63px;

		&-copy,
		&-pay,
		&-soc,
		&-create {
			@include flex();
			@include box-sizing();
			padding: 0 15px;
		}

		&-copy {
		}

		&-pay {
			text-align: center;

			&-item {
				display: inline-block;
				vertical-align: middle;
				margin: 0 6px;

				svg {
					path {
						fill: #a6a6a6;
					}
				}

				&:hover {

					svg {
						path {
							fill: $colorYellow;
						}
					}
				}
			}
		}

		&-soc {
			text-align: center;

			&-item {
				display: inline-block;
				vertical-align: middle;
				margin: 0 4px;

				svg {
					path {
						fill: #a6a6a6;
					}
				}

				&:hover {

					svg {
						path {
							fill: $colorYellow;
						}
					}
				}
			}
		}

		&-create {
			text-align: right;
		}
	}
}